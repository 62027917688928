import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Button,
  CssBaseline,
  useTheme,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import HeroImage from "./images/hero-image.png"; // Make sure to add a hero image
import Footer from "./Footer";

const officials = [
  { name: "Kelvin Oteri", position: "President", phone: "(407) 314-8308" },
  {
    name: "Efe Odiete Osanyinjobi",
    position: "Vice President",
  },
  {
    name: "Elizabeth Onaodowan",
    position: "Treasurer",
  },
  {
    name: "Rukevwe Ojameruaye",
    position: "Secretary",
    phone: "(623) 252-9469",
  },

  {
    name: "Veronica Gbago",
    position: "PRO / Otota",
  },
];
const Home = () => {
  const theme = useTheme();
  const classes = {
    hero: {
      backgroundSize: "cover",
      backgroundPosition: "top",
      height: "500px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      textAlign: "center",
    },
    heroText: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      padding: theme.spacing(2),
    },
    content: {
      marginTop: theme.spacing(4),
    },
  };

  return (
    <>
      <CssBaseline />

      <Box
        sx={{
          ...classes.hero,
          ...{
            backgroundImage: `url(${HeroImage})`,
          },
        }}
      >
        <Box sx={classes.heroText}>
          <Typography variant="h2">Welcome to UPUCF</Typography>
          <Typography variant="h5" sx={{ mb: 2 }}>
            “Honesty and Justice” will make Urhobo progress
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            href="https://www.eventbrite.com/e/virtual-5k-walk-and-run-registration-942911181727"
            target="_blank"
            size="large"
          >
            Register for 5K Run & Walk Fundraiser
          </Button>
          <Button
            variant="contained"
            color="primary"
            href="https://upuamerica.org/"
            target="_blank"
            sx={{ m: 2 }}
            size="large"
          >
            Register for 2024 UPUA Convention
          </Button>
        </Box>
      </Box>
      <Container sx={classes.content}>
        <Typography variant="h4" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" paragraph>
          UPUCF is a non-profit association dedicated to bringing the Urhobo
          people in the Central Florida Area together under a common future. Our
          mission is to foster community, support, and growth through various
          programs and initiatives.
        </Typography>

        <Grid container spacing={2}>
          {officials.map((o) => (
            <Grid item xs={6} md={4}>
              <Card sx={{ height: 450 }}>
                <CardMedia
                  sx={{ height: "70%" }}
                  image={require(`./images/${o.name}.jpeg`)}
                  title={o.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {o.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {o.position}
                  </Typography>
                  <Typography variant="body2" color="text.primary">
                    <a href={`tel:${o.phone}`}>{o.phone}</a>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Button
          variant="contained"
          color="secondary"
          href="https://www.eventbrite.com/e/virtual-5k-walk-and-run-registration-942911181727"
          target="_blank"
          size="large"
        >
          Register for 5K Run & Walk Fundraiser
        </Button>
        <Button
          variant="contained"
          color="primary"
          href="https://upuamerica.org/"
          target="_blank"
          sx={{ m: 2 }}
          size="large"
        >
          Register for 2024 UPUA Convention
        </Button>
      </Container>
    </>
  );
};

export default Home;
