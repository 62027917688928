// App.tsx
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  RouterProvider,
  createBrowserRouter,
  Outlet,
} from "react-router-dom";
import { Container } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";

// Placeholder components for pages
const AboutUs = () => <div>About Us Page</div>;
const Members = () => <div>Members Page</div>;
const Gallery = () => <div>Gallery Page</div>;
const Contact = () => <div>Contact Page</div>;

function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Home />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/members",
        element: <Home />,
      },
      {
        path: "/products/:productId",
        element: <Home />,
      },
      {
        path: "/about-us",
        element: <Home />,
      },
      {
        path: "/contact",
        element: <Home />,
      },
      {
        path: "/donate",
        element: <Home />,
      },
    ],
  },
]);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
